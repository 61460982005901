<template>
  <div class="user-management-container">
    <nxp-search
      :config-list="searchConfigList"
      @clickSearch="clickSearch"
      @resetForm="resetForm"
    />
    <!-- 表格 -->
    <select-table
      :accessories="nxpTabelAccessories"
      only-key-id="id"
      :table-columns="tableColumns"
      :table-data="tableData"
      :table-loading="tableLoading"
      :w-theight="320"
      @delSelect="delSelect"
      @fetchData="fetchData"
      @fetchTableSelection="fetchTableSelection"
    />
  </div>
</template>

<script>
import { queryActionLogDataList } from "@/project/eztree/baseApi/commonApi";
export default {
  name: "Logger",
  components: {},
  data() {
    return {
      queryForm: {},
      select_table_data: [],
      searchConfigList: [
        {
          type: "text",
          showName: false,
          model: "",
          param: "phone",
          label: "用户手机号",
        },
        {
          type: "daterange",
          showName: false,
          model: "",
          param: "date",
          label: "日期",
        },
      ],
      tableLoading: false,
      tableData: [],
      nxpTabelAccessories: {
        pageType: "",
        selectType: "",
        pageNo: 1,
        pageSize: 15,
        total: 0,
        height: this.$baseTableHeight(1),
      },
    };
  },
  computed: {
    tableColumns() {
      let columns = [
        { label: "用户手机", prop: "phone" },
        // 类型，1为上传苗木，2为号苗报告，3为选型报告，4拨打电话，5植物识别，6访问分享
        {
          label: "登录方式",
          prop: "action_type",
          render: (h, params) => {
            return h(
              "el-tag",
              {
                attrs: {
                  type:
                    params.row.action_type == "1"
                      ? "info"
                      : params.row.action_type == "2"
                      ? "warning"
                      : params.row.action_type == "3"
                      ? "warning"
                      : params.row.action_type == "4"
                      ? ""
                      : params.row.action_type == "5"
                      ? "success"
                      : params.row.action_type == "6"
                      ? "success"
                      : "",
                  effect: "plain",
                  color:
                    params.row.action_type == "1"
                      ? "#f6f8f9"
                      : params.row.action_type == "2"
                      ? "#e8f4ff"
                      : params.row.action_type == "3"
                      ? "#f6f8f9"
                      : params.row.action_type == "4"
                      ? "#f6f8f9"
                      : params.row.action_type == "5"
                      ? "success"
                      : params.row.action_type == "6"
                      ? "success"
                      : "",
                },
              },
              params.row.action_type == "1"
                ? "上传苗木"
                : params.row.action_type == "2"
                ? "号苗报告"
                : params.row.action_type == "3"
                ? "选型报告"
                : params.row.action_type == "4"
                ? "拨打电话"
                : params.row.action_type == "5"
                ? "植物识别"
                : params.row.action_type == "6"
                ? "访问分享"
                : "其他"
            );
          },
        },

        {
          label: "内容",
          prop: "action_content",
          tooltip: true,
        },
        { label: "操作次数", prop: "action_num" },
        {
          label: "创建时间",
          prop: "created_at",
          width: "160px",
        },
        {
          label: "更新时间",
          prop: "last_active_at",
          width: "160px",
        },
      ];
      return columns;
    },
  },
  watch: {},
  mounted() {},
  created() {},
  methods: {
    // 初始化
    async initQuery() {
      this.select_table_data = [];
      this.tableLoading = true;
      let params = Object.assign({}, this.queryForm);
      params.page = this.nxpTabelAccessories.pageNo;
      params.page_size = this.nxpTabelAccessories.pageSize;
      const { date } = params;
      if (date) {
        params.start_time = date[0];
        params.end_time = date[1];
        console.log("params", params, date);
        delete params.date;
      }
      queryActionLogDataList(params).then(({ code, data }) => {
        if (code == "200") {
          this.tableData = (data && data.list) || [];
          this.nxpTabelAccessories.total = data.total_count;
        }
        this.tableLoading = false;
      });
    },
    // 分页查询
    fetchData(val) {
      this.select_table_data = [];
      this.nxpTabelAccessories.pageNo = val.pageNo;
      this.nxpTabelAccessories.pageSize = val.pageSize;
      this.initQuery();
    },
    // 模糊查询
    clickSearch(val) {
      this.queryForm = val;
      this.nxpTabelAccessories.pageNo = 1;
      this.initQuery();
    },
    resetForm() {
      this.queryForm = {};
      this.nxpTabelAccessories.pageNo = 1;
      this.initQuery();
    },
    handleEdit(row, action) {
      this.$refs["edit"].showEdit(row, action);
    },
    fetchTableSelection(data) {
      this.select_table_data = data;
    },
    delSelect(row, action) {
      let login_ids = this.select_table_data.map((ele) => {
        return ele.id;
      });
      // delLoginLogDataItem({ login_ids }).then(({ code, data }) => {
      //   if (code == "200") {
      //     this.$baseMessage("删除成功", "success", "vab-hey-message-success");
      //     this.initQuery();
      //     this.btnChangeEnable = false;
      //   }
      // });
    },
  },
};
</script>
